<template>
  <h1>This is Register page</h1>
</template>

<script>
export default {
  name: "Register",
};
</script>

<style scoped></style>
